import { LEGACY_PHP_API_DATE_FORMAT } from "@/helpers/constants";
import * as selectOptions from "@/helpers/selectOptions";
import { format } from "date-fns";
import { capitalize } from "lodash";
import { correctLineOfBusiness } from "@/forms/utils";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";

function formatDate(d: string): string {
  if (!d || d.trim().length === 0 || isNaN(new Date(d).getTime())) {
    return "N/A";
  }
  return format(new Date(d), LEGACY_PHP_API_DATE_FORMAT);
}

export const legacyTableColumns = {
  agentCode: {
    formatDataKey: "Agency ID",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 150,
    position: 0
  },
  quoteApplication: {
    formatDataKey: "Client Name",
    formatDataValue: (arg: any) => {
      if (arg && arg.applicant) {
        const { firstName, lastName } = arg.applicant;
        if (firstName && lastName) {
          return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
            lastName
          )}`;
        }
      }
      return "N/A";
    },
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  effectiveDate: {
    formatDataKey: "Effective Date",
    formatDataValue: formatDate,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 140,
    position: 2
  },
  //TODO: Verisk req
  policyType: {
    formatDataKey: "LOB",
    formatDataValue: (arg: string) => correctLineOfBusiness(arg),
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 90,
    position: 3
  },
  companyNumbers: {
    formatDataKey: "Comp #",
    formatDataValue: (arg: any[]) => getCompanyNumber(arg),
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 90,
    position: 4
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => capitalize(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.userStatus,
    position: 5,
    colWidth: 150
  }
};

function getCompanyNumber(companyNumbers: any[] | null): number {
  let companyNumber = companyNumbers ? companyNumbers[0] : "N/A";
  if (companyNumber == "99") {
    companyNumber = "20";
  }
  return companyNumber;
}
