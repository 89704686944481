import { LEGACY_PHP_API_DATE_FORMAT } from "@/helpers/constants";
import * as selectOptions from "@/helpers/selectOptions";
import { format } from "date-fns";
import { capitalize, get } from "lodash";
import { correctLineOfBusiness } from "@/forms/utils";
import TableColumnRiskAddress from "../QuotesListView/columnRiskAddressToolTip.vue";
import TableColumnCompanies from "../QuotesListView/columnRatingCompaniesToolTip.vue";

const notAvailableString = "N/A";

function formatDate(d: string): string {
  if (!d || d.trim().length === 0 || isNaN(new Date(d).getTime())) {
    return notAvailableString;
  }
  return format(new Date(d), LEGACY_PHP_API_DATE_FORMAT);
}

export const tableColumns = {
  agentCode: {
    formatDataKey: "Agency ID",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 140,
    position: 0
  },
  createdByData: {
    formatDataKey: "Agent",
    formatDataValue: (arg: any) => {
      if (arg) {
        const { firstName, lastName } = arg;
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        }
      }
      return notAvailableString;
    },
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    sortMethod: (a: any, b: any) => {
      return get(a, "createdByData.firstName", "").localeCompare(
        get(b, "createdByData.firstName", ""),
        undefined,
        {
          sensitivity: "base"
        }
      );
    }
  },
  quoteApplication: {
    formatDataKey: "Client Name",
    formatDataValue: (arg: string) => arg,
    formatDataComponent: TableColumnRiskAddress,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    sortMethod: (a: any, b: any) => {
      return get(a, "quoteApplication.applicant.firstName", "").localeCompare(
        get(b, "quoteApplication.applicant.firstName", ""),
        undefined,
        {
          sensitivity: "base"
        }
      );
    },
    colWidth: 200
  },
  dateSubmitted: {
    formatDataKey: "Date Submitted",
    formatDataValue: formatDate,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 145,
    position: 6
  },
  effectiveDate: {
    formatDataKey: "Effective Date",
    formatDataValue: formatDate,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 140,
    position: 5
  },
  //TODO: Verisk req
  policyType: {
    formatDataKey: "LOB",
    formatDataValue: (arg: string) => correctLineOfBusiness(arg),
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 70,
    position: 2
  },
  "ratingSelectedData.companyNumber": {
    formatDataKey: "Comp #",
    formatDataValue: (arg: string) => getCompanyNumber(arg),
    formatDataComponent: TableColumnCompanies,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 90,
    position: 3,
    sortMethod: (a: any, b: any) => {
      const aNum = getCompanyNumber(
        get(a, "ratingSelectedData.companyNumber", 0)
      );
      const bNum = getCompanyNumber(
        get(b, "ratingSelectedData.companyNumber", 0)
      );
      return aNum > bNum ? 1 : aNum < bNum ? -1 : 0;
    }
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => capitalize(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.userStatus,
    position: 7,
    colWidth: 140
  },
  "riskAddress.county": {
    formatDataKey: "County",
    formatDataValue: (arg: string) => (arg ? arg : notAvailableString),
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    colWidth: 120,
    position: 7
  }
};

export function getCompanyNumber(companyNumber: string | null): string {
  if (companyNumber == "99") {
    companyNumber = "20";
  }
  return companyNumber ? companyNumber.toString() : notAvailableString;
}
