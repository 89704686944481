
import { get } from "lodash";
import Vue from "vue";
import { getCompanyNumber } from "./listViewColumns";

interface IRatingCompanyColumn {
  selectedCompanyNumber: string;
}

export default Vue.extend({
  name: "columnRatingCompaniesToolTip",
  data(): IRatingCompanyColumn {
    return {
      selectedCompanyNumber: ""
    };
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    processCompanyNumber() {
      this.selectedCompanyNumber = get(
        this.row.ratingSelectedData,
        "companyNumber",
        ""
      );
      return getCompanyNumber(this.selectedCompanyNumber);
    },
    processRatingCompanyNumbers() {
      let ratingCompanies = get(this.row, "companyNumbers", []);
      ratingCompanies = ratingCompanies.map((e: string | null) =>
        getCompanyNumber(e)
      );
      const companyLength = ratingCompanies.length;
      const companyString =
        companyLength === 1
          ? `Rating Company`
          : companyLength > 1
          ? `Rating Companies`
          : "No rating companies";
      let res = companyLength
        ? `${companyString}: ${ratingCompanies.sort().join(", ")}`
        : "";

      if (this.selectedCompanyNumber) {
        res = this.row?.ratingSelectedData?.rate?.Quote?.specificCompanyName;
      }
      return res;
    }
  }
});
